<script setup>
import { ref, onMounted } from 'vue';
import AnimatedNumber from './AnimatedNumber.vue';


// Example stats
const stats = ref({
    courses: 145244,
    reviewCycles: 243228,
    comments: 1973939,
    uploaders: 2722,
    reviewers: 599399,
    developers: 109739,
    users: 206016
});

// Example client logos
const logos = ref([
    '/logos/clients/1.png',
    '/logos/clients/2.png',
    '/logos/clients/3.png',
    '/logos/clients/4.png',
    '/logos/clients/5.png',
    '/logos/clients/6.png',
    '/logos/clients/7.png',
    '/logos/clients/8.png',
    '/logos/clients/9.png',
    '/logos/clients/10.png',
    '/logos/clients/11.png',
    '/logos/clients/12.png',
    '/logos/clients/13.png',
    '/logos/clients/14.png',
    '/logos/clients/15.png',
    '/logos/clients/16.png',
]);

logos.value.sort(() => Math.random() - 0.5);
//get for rows of logos
const logoRows = ref([])
logoRows.value = logos.value.reduce((acc, curr, i) => {
    if (i % 3 === 0) {
        acc.push([curr]);
    } else {
        acc[acc.length - 1].push(curr);
    }
    return acc;
}, []);


onMounted(() => {
    fetch("/api/object-stats")
        .then((response) => response.json())
        .then((data) => {
            stats.value = data;
        });
});

const formatNumberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
</script>

<template>


    <!-- Stats Section -->
    <div class="flex items-center justify-center">
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 lg:divide-x">
            <div class="stat-card">
                <h3 class="text-4xl lg:text-5xl font-bold ">
                    {{ formatNumberWithCommas(stats.comments) }}
                </h3>
                <h3 class=" text-xl font-semibold ">Comments</h3>

            </div>
            <div class="stat-card">

                <h3 class="text-4xl lg:text-5xl font-bold">
                    {{ formatNumberWithCommas(stats.courses) }}

                </h3>
                <h3 class=" text-xl font-semibold">Courses</h3>

            </div>
            <div class="stat-card">

                <h3 class=" text-4xl lg:text-5xl font-bold">
                    {{ formatNumberWithCommas(stats.reviewers) }}
                </h3>
                <h3 class="text-xl font-semibold">Reviewers</h3>
            </div>
            <div class="stat-card">

                <h3 class="text-4xl lg:text-5xl font-bold">
                    {{ formatNumberWithCommas(stats.developers) }}
                </h3>
                <h3 class="text-xl font-semibold ">Developers</h3>
            </div>
        </div>

    </div>


</template>

<style scoped>
.logo {
    @apply grayscale max-h-8 opacity-20
}

.stat-card {
    @apply w-64 flex flex-col items-center bg-white bg-opacity-50 text-blue-700;

}
</style>
