<script setup>
import { ref, onMounted, onUnmounted, watchEffect, nextTick } from "vue";
import { useRouter, useRoute, RouterLink } from "vue-router";
import VueScrollTo from "vue-scrollto";
import { useUserStore } from "../stores/userStore";
import logoCloud from "../components/common/logoCloud.vue";
import testimonial from "../components/common/testimonial.vue";
import stats from "../components/common/stats.vue";
import tabFeatures from "../components/common/tabFeatures.vue";
const userStore = useUserStore();

const router = useRouter();
const route = useRoute();

const tools = [
  "Storyline 3",
  "Captivate Classic",
  "Lectora",
  "iSpring",
  "Storyline 360",
  "Captivate",
  "Rise",
  "Gomo",
  "Essemble",
  "PowerPoint",
  "Word",
  "PDFs",
  "SAP Enable Now",
];

const brandColors = [
  "text-green-600",
  "text-blue-700",
  "text-fuchsia-500",
];

const testimonials = [
  { title: "The Captivate Teacher", name: "Paul Wilson", quote: "Gravida quam mi erat tortor neque molestie. Auctor aliquet at porttitor a enim nunc suscipit tincidunt nunc. Et non lorem tortor posuere. Nunc eu scelerisque interdum eget tellus non nibh scelerisque bibendum.", image: "/imgs/paulwilson.jpg" }
]

const selectedTool = ref("Captivate");
const randomColor = ref("text-fuchsia-600");
const isChanging = ref(false);
const toolIndex = ref(0);
const showScrollTop = ref(false);

const isVideoModalOpen = ref(false);
const videoUrl = 'https://www.youtube.com/embed/tFAxIAdISJM?autoplay=1'; // Replace with your video URL


watchEffect(() => {
  if (route.hash) {
    nextTick(() => {
      VueScrollTo.scrollTo(route.hash, 500);
    });
  }
});

const checkScroll = () => {
  showScrollTop.value = window.scrollY > window.innerHeight;
};

onMounted(() => {
  window.addEventListener("scroll", checkScroll);
  //pick a new tool from the array every 5 seconds
  setInterval(pickTool, 3000);
  //fetch stats from /api/object-stats

});

onUnmounted(() => {
  window.removeEventListener("scroll", checkScroll);
});

//pick a new tool from the array
const pickTool = () => {
  isChanging.value = true;
  toolIndex.value++;
  //if we are at the end of the array, start over
  if (toolIndex.value >= tools.length) {
    toolIndex.value = 0;
  }
  selectedTool.value = tools[toolIndex.value];
  let currentColor = randomColor.value;
  randomColor.value = brandColors[Math.floor(Math.random() * brandColors.length)];
  //if the color is the same, pick a new one
  if (currentColor === randomColor.value) {
    randomColor.value = brandColors[Math.floor(Math.random() * brandColors.length)];
  }
  setTimeout(() => {
    isChanging.value = false;
  }, 300);
};

</script>

<template>

  <div class="bg-white flex flex-col gap-12">
    <div class="relative isolate px-6 pt-6">
      <div class="mx-auto py-8 ">
        <div class="text-center">
          <h1 class="text-4xl font-bold  text-gray-900 sm:text-6xl">
            Review <transition name="fade">
              <span v-if="!isChanging" class="font-bold max-w-80 min-w-80" :class="randomColor"
                key="{{ selectedTool }}">{{
                  selectedTool }}</span>
            </transition> <br> courses faster <span class="italic">and</span> better
          </h1>
          <p class="mx-auto mt-6 text-md  text-gray-700 max-w-6xl">
            Streamline your eLearning experience with our comprehensive platform. Securely manage, review, and access
            courses while maintaining brand integrity. Our AI-powered review system and flexible features adapt to your
            corporate needs, ensuring quality and confidentiality through unlimited review cycles.<br>
            You lead, we follow—your rules, your brand, your legacy
          </p>
          <div class="mt-10 flex flex-col items-center justify-center gap-y-6">
            <div class="flex flex-col sm:flex-row gap-4">
              <router-link to="/join"
                class="py-2 px-4 rounded-full font-semibold  text-white ring ring-fuchsia-50  bg-fuchsia-600 hover:bg-fuchsia-700 hover:ring-fuchsia-200">
                <span class="text-sm">Get 1 month free</span>
              </router-link>
              <button @click="isVideoModalOpen = true"
                class="py-2 px-4 rounded-full  ring ring-blue-50 hover:bg-blue-50 hover:ring-blue-100 hover:ring-2">
                <font-awesome-icon icon="fa-solid fa-play" class="text-blue-400" />
                <span class="pl-2 text-sm text-gray-700"> Watch video</span>
              </button>


            </div>
            <router-link to=" /login?signup" class="text-sm font-semibold leading-6 text-gray-900">Free account for
              Reviewers, Developers, and
              Uploaders
              <span aria-hidden="true">→</span></router-link>
          </div>
        </div>

      </div>
    </div>
    <logoCloud />

    <stats />
    <tabFeatures />
    <section class="py-8 bg-blue-100/50 relative">
      <div class="container mx-auto px-1 flex flex-col lg:flex-row items-center">
        <div class="lg:w-1/3 ">
          <img src="/imgs/branding.png" alt="Feature Screenshot" class="object-cover object-right " loading="lazy">
        </div>
        <div class="lg:w-2/3 lg:pl-12 mt-8 lg:mt-0">
          <h2 class="text-4xl font-bold text-gray-900 mb-4">Your Brand: Front and Center</h2>
          <p class="text-lg text-gray-600">
            Feeling like an after thought? Review My eLearning is designed to be a comprehensive solution for your
            eLearning review needs. Our platform is built to be user-friendly, efficient, and secure, with features that
            cater to your unique requirements.
          </p>
          <div class="mt-6">
            <ul class="list-disc list-inside text-gray-700">
              <li>Comment statuses and assignments</li>
              <li>Invite options</li>
              <li>Share/hide comments</li>
              <li>Branding</li>
              <li>Uploaders</li>
            </ul>
          </div>
        </div>
      </div>
    </section>


    <section class="py-10 bg-green-400/10">
      <div class="container mx-auto px-6">
        <div class="text-center mb-12">
          <h2 class="text-4xl font-bold text-gray-800">Custom Portfolio</h2>
          <p class="mt-4 text-lg text-gray-600">Showcase your best work</p>
        </div>

        <div class="flex flex-col lg:flex-row items-center">
          <div class="lg:w-1/2 lg:pr-12">
            <p class="text-gray-700 text-lg mb-6">
              Review My eLearning's Portfolio feature allows you to display your best work on a sleek interface with
              just a few clicks. It's time to show off what you've accomplished.
            </p>

            <ol class="list-decimal list-inside text-gray-700 text-lg">
              <li>Customize your portfolio with your logo and brand colors</li>
              <li>Share your portfolio with clients, co-workers, and the world.</li>
              <li>Track who viewed your courses.</li>
            </ol>

          </div>
          <div class="lg:w-1/2 lg:pl-12 mt-8 lg:mt-0">
            <img src="/imgs/gforcePortfolio.jpg" alt="Feature Screenshot" class="w-full rounded-lg shadow-lg"
              loading="lazy">

          </div>
        </div>
      </div>
    </section>


    <div class="mx-auto  text-center">
      <h2 class="text-2xl font-semibold leading-7 text-green-600">
        Develop eLearning content faster
        <span class="italic">and</span> better
      </h2>
      <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        All the features you want and expect in a modern review tool.
      </p>
      <p class="mt-6 text-lg leading-8 text-gray-600">
        Review My eLearning offers a robust, user-friendly solution that greatly
        enhances eLearning content's efficiency and quality. The platform fosters
        clear communication between creators, stakeholders, and reviewers, addressing
        both collaborative feedback needs and deeper technical requirements.
      </p>
    </div>
    <div class="bg-fuchsia-50/50 text-gray-800 flex flex-col items-center pt-8 px-4 ">
      <div class="" id="ai_reviewers">
        <h1 class="text-3xl font-semibold mb-4 text-center">
          Harness the power of AI to improve your courses.
        </h1>
        <p class="text-lg mb-12 text-center">
          Our AI Reviewers are designed to assist in the evaluation of your course
          by providing feedback through AI technology, specifically tailored to
          possess expertise in various crucial areas.
        </p>
      </div>
      <div class="flex flex-col md:flex-row w-full bg-white rounded-lg shadow-lg overflow-hidden">
        <div class="bg-fuchsia-50 text-gray-800 p-8 w-full md:w-1/2">
          <h2 class="text-xl font-bold mb-2">Instructional Design</h2>
          <p class="mb-4 hide-mobile">
            This AI reviewer focuses on adult learning theories and eLearning best
            practices, ensuring that your course design is pedagogically sound.
          </p>
          <h2 class="text-xl font-bold mb-2">Accessibility</h2>
          <p class="mb-4 hide-mobile">
            Reviews your course for compliance with standards such as Section 508 and
            WCAG, making sure it's accessible to all users.
          </p>
          <h2 class="text-xl font-bold mb-2">User Experience (UX)</h2>
          <p class="mb-4 hide-mobile">
            Evaluates the user experience of the course, ensuring it is intuitive and
            user-friendly.
          </p>
          <h2 class="text-xl font-bold mb-2">Proofreader</h2>
          <p class="mb-4 hide-mobile">
            Checks your text for any grammar and spelling mistakes, ensuring clear and
            correct course content.
          </p>
          <h2 class="text-xl font-bold mb-2">Diversity & Inclusion</h2>
          <p class="mb-4 hide-mobile">
            Assesses the course material for potential biases and inclusivity,
            ensuring content fairness and respect for diversity.
          </p>
          <h2 class="text-xl font-bold mb-2">Subject Matter Expert</h2>
          <p class="mb-4 hide-mobile">
            Verifies the accuracy of the course content, providing expertise in the
            specific subject matter of the course.
          </p>
        </div>
        <div class="w-full md:w-1/2 bg-green-900">
          <video class="w-full h-full object-cover object-left rounded-t-lg lg:rounded-none" loop autoplay muted
            playsinline>
            <source src="/imgs/ai_reviewers.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>

    <div id="testimonials">
      <div v-for="(testimonial, index) in testimonials" :key="index">
        <testimonial :reverse="index % 2 === 0" :title="testimonial.title" :quote="testimonial.quote"
          :name="testimonial.name" :image="testimonial.image" />
      </div>

    </div>
    <logoCloud />
    <div class=" px-2 pb-8" id="features">
      <h1 class="text-3xl font-semibold mb-4 text-center pb-2">Features</h1>
      <dl class="grid grid-cols-1 sm:grid-cols-2 gap-4  lg:grid-cols-4 ">
        <div class="feature-card">
          <dt class="feature-header">
            <div class=" ">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>athletics</title>
                <g fill="#ffffff">
                  <circle cx="18" cy="3" r="3" fill="#ffffff"></circle>
                  <path
                    d="M6.729,15.56a3.938,3.938,0,0,1-.742-.906L.592,21.128l1.536,1.28,5.281-6.336C7.074,15.83,6.829,15.648,6.729,15.56Z"
                    fill="#ffffff"></path>
                  <path
                    d="M21.632,10.735,18.439,11.8l-3.1-5.035A1.981,1.981,0,0,0,15,6.338a2.294,2.294,0,0,0-.884-.451L8.3,4.046a1,1,0,0,0-1.133.4L4.613,8.277l1.664,1.11L8.416,6.18l3.14.989L7.9,11.232a1.991,1.991,0,0,0,.149,2.825c.359.316,4.4,3.084,4.4,3.084L9.293,20.293l1.414,1.414,4-4a1,1,0,0,0-.139-1.53L11.1,13.652,14.752,9.6l2.394,3.922A1,1,0,0,0,18,14a1.019,1.019,0,0,0,.316-.051l3.949-1.317Z"
                    fill="#ffffff"></path>
                </g>
              </svg>
            </div>
            Agile Development
          </dt>
          <dd class="mt-2 leading-7 text-gray-700">
            Supports iterative development by allowing continuous feedback,
            facilitating swift adaptation and content improvement.
          </dd>
        </div>
        <div class="feature-card">
          <dt class="feature-header">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>ic_assignment_ind_24px</title>
                <g fill="#ffffff">
                  <path
                    d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z">
                  </path>
                </g>
              </svg>
            </div>
            Comment Statuses and Assignments
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Offers a structured method for stakeholders to provide feedback with
            assignable statuses and approvals.
          </dd>
        </div>
        <div class="feature-card">
          <dt class="feature-header">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>letter</title>
                <g fill="#ffffff">
                  <path
                    d="M13.4,14.6A2.3,2.3,0,0,1,12,15a2.3,2.3,0,0,1-1.4-.4L0,8.9V19a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V8.9Z"
                    fill="#ffffff"></path>
                  <path
                    d="M21,2H3A3,3,0,0,0,0,5V6a1.05,1.05,0,0,0,.5.9l11,6a.9.9,0,0,0,.5.1.9.9,0,0,0,.5-.1l11-6A1.05,1.05,0,0,0,24,6V5A3,3,0,0,0,21,2Z">
                  </path>
                </g>
              </svg>
            </div>
            Invite Options
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Flexible login and public invite links promote easier collaboration and
            broader reviews.
          </dd>
        </div>
        <div class="feature-card">
          <dt class="feature-header">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>b-eye</title>
                <g fill="#ffffff">
                  <path
                    d="M20.565,7.678,19.131,9.111A19.25,19.25,0,0,1,21.783,12c-1.142,1.56-4.829,6-9.783,6a8.369,8.369,0,0,1-1.6-.162l-1.64,1.64A10.679,10.679,0,0,0,12,20c7.071,0,11.655-7.163,11.847-7.468a1,1,0,0,0,0-1.064A19.827,19.827,0,0,0,20.565,7.678Z">
                  </path>
                  <path
                    d="M22.707,1.293a1,1,0,0,0-1.414,0L17.235,5.351A11.27,11.27,0,0,0,12,4C4.929,4,.345,11.163.153,11.468a1,1,0,0,0,0,1.064,19.933,19.933,0,0,0,4.859,5.042L2.293,20.293a1,1,0,1,0,1.414,1.414l19-19A1,1,0,0,0,22.707,1.293ZM2.217,12A16.7,16.7,0,0,1,8.083,6.931a4.9,4.9,0,0,0,.711,6.861L6.456,16.13A18.156,18.156,0,0,1,2.217,12Z"
                    fill="#ffffff"></path>
                </g>
              </svg>
            </div>
            Share/Hide Comments
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Control over comment visibility suits various review scenarios.
          </dd>
        </div>
        <div class="feature-card">
          <dt class="feature-header">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>stamp</title>
                <g fill="#ffffff">
                  <path fill="#ffffff"
                    d="M21.949,13.596L15,11.279V5.721l2.317-0.773C17.725,4.812,18,4.43,18,4V1H6v3 c0,0.43,0.275,0.812,0.683,0.948L9,5.721v5.559l-6.949,2.316C0.826,14.004,0,15.15,0,16.441V19h24v-2.559 C24,15.15,23.174,14.004,21.949,13.596z">
                  </path>
                  <rect x="2" y="21" width="20" height="2"></rect>
                </g>
              </svg>
            </div>
            Branding
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Personalization features (logo, colors, subdomain, title) offer a
            professional interface for clients and stakeholders.
          </dd>
        </div>
        <div class="feature-card">
          <dt class="feature-header">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>cloud-upload</title>
                <g fill="#ffffff">
                  <path
                    d="M14,2a9.96,9.96,0,0,0-9.855,8.3A6,6,0,0,0,6,22h8A10,10,0,0,0,14,2Zm2.957,12.7a.5.5,0,0,1-.457.3H13v3a1,1,0,0,1-2,0V15H7.5a.5.5,0,0,1-.372-.834l4.5-5a.516.516,0,0,1,.744,0l4.5,5A.5.5,0,0,1,16.957,14.7Z"
                    fill="#ffffff"></path>
                </g>
              </svg>
            </div>
            Uploaders
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Multiple course uploaders enable collaborative work for larger teams.
          </dd>
        </div>
        <div class="feature-card">
          <dt class="feature-header">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>attachment</title>
                <g fill="#ffffff">
                  <path fill="#ffffff"
                    d="M11.5,24C7.4,24,4,20.6,4,16.5v-11C4,2.5,6.5,0,9.5,0S15,2.5,15,5.5v10c0,1.9-1.6,3.5-3.5,3.5S8,17.4,8,15.5 V7c0-0.6,0.4-1,1-1s1,0.4,1,1v8.5c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-10C13,3.6,11.4,2,9.5,2S6,3.6,6,5.5v11 c0,3,2.5,5.5,5.5,5.5s5.5-2.5,5.5-5.5V4c0-0.6,0.4-1,1-1s1,0.4,1,1v12.5C19,20.6,15.6,24,11.5,24z">
                  </path>
                </g>
              </svg>
            </div>
            Attachments
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Linking files to comments for richer, context-specific feedback.
          </dd>
        </div>
        <div class="feature-card">
          <dt class="feature-header">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>jump-rope</title>
                <g fill="#ffffff">
                  <circle cx="12" cy="3" r="3" fill="#ffffff"></circle>
                  <path
                    d="M22.236,9.313,14.275,7.038A1.02,1.02,0,0,0,14,7H10a1.02,1.02,0,0,0-.275.038L1.764,9.313l.549,1.923L9,9.326V19h2V16h2v8h2V9.326l6.687,1.91Z"
                    fill="#ffffff"></path>
                  <path d="M11,21.931A8.008,8.008,0,0,1,4,14V13H2v1a10.013,10.013,0,0,0,9,9.949Z"></path>
                  <path d="M20,13v1a7.984,7.984,0,0,1-3,6.235v2.412A10,10,0,0,0,22,14V13Z"></path>
                </g>
              </svg>
            </div>
            Jump to Slide
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Time-saving feature allowing swift location and addressal of specific
            feedback points.
          </dd>
        </div>
        <div class="feature-card">
          <dt class="feature-header">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>devto</title>
                <g fill="#ffffff">
                  <path
                    d="M6.9,9.657a.959.959,0,0,0-.572-.214H5.47v5.131h.857A.954.954,0,0,0,6.9,14.36a.747.747,0,0,0,.286-.641V10.3A.752.752,0,0,0,6.9,9.657ZM20.844,1H3.156A2.156,2.156,0,0,0,1,3.151v17.7A2.156,2.156,0,0,0,3.156,23H20.844A2.156,2.156,0,0,0,23,20.849V3.151A2.156,2.156,0,0,0,20.844,1ZM8.572,13.728A2.243,2.243,0,0,1,6.2,16.048H3.919V7.923H6.246a2.243,2.243,0,0,1,2.326,2.322v3.483Zm4.944-4.354H10.9v1.887h1.6v1.452H10.9V14.6h2.617v1.452H10.463a.991.991,0,0,1-1.017-.967V8.941a.992.992,0,0,1,.968-1.016h3.1V9.374Zm5.09,5.662c-.648,1.51-1.81,1.209-2.33,0L14.384,7.925h1.6l1.459,5.585L18.9,7.925h1.6l-1.889,7.111Z"
                    fill="#ffffff"></path>
                </g>
              </svg>
            </div>
            Developers
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Support for unlimited developers enhances team collaboration.
          </dd>
        </div>


        <div class="feature-card">
          <dt class="feature-header">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>plug-2</title>
                <g fill="#ffffff">
                  <path
                    d="M12.414,16l2-2L13,12.586l-2,2L9.414,13l2-2L10,9.586l-2,2L6.671,10.258a1,1,0,0,0-1.414,0l-1.5,1.5a5.989,5.989,0,0,0-.644,7.715l-2.82,2.82a1,1,0,1,0,1.414,1.414l2.82-2.82a5.989,5.989,0,0,0,7.716-.645l1.5-1.5a1,1,0,0,0,0-1.414Z"
                    fill="#ffffff"></path>
                  <path
                    d="M17.329,13.742a1,1,0,0,0,1.414,0l1.5-1.5a5.989,5.989,0,0,0,.644-7.715l2.82-2.82A1,1,0,1,0,22.293.293l-2.82,2.82a5.989,5.989,0,0,0-7.716.645l-1.5,1.5a1,1,0,0,0,0,1.414Z">
                  </path>
                </g>
              </svg>
            </div>
            Tool Support
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Compatibility with major eLearning tools, catering to diverse eLearning
            professionals.
          </dd>
        </div>
        <div class="feature-card">
          <dt class="feature-header">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>funnel</title>
                <g fill="#ffffff">
                  <path fill="#ffffff"
                    d="M24,4c0-3.158-7.543-4-12-4S0,0.842,0,4c0,0.485,0.195,0.91,0.513,1.291L9,16.333V23c0,0.552,0.448,1,1,1h4 c0.552,0,1-0.448,1-1v-6.667l8.466-11.017C23.797,4.929,24,4.496,24,4z M12,2c5.925,0,9.582,1.278,9.986,2 C21.582,4.722,17.925,6,12,6C5.957,6,2.273,4.67,1.994,4.042C2.273,3.33,5.957,2,12,2z">
                  </path>
                </g>
              </svg>
            </div>
            Sort and Filter
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Enhanced usability and navigation for projects with extensive courses and
            feedback.
          </dd>
        </div>
        <div class="feature-card">
          <dt class="feature-header">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>privacy</title>
                <g fill="#ffffff">
                  <path
                    d="M21.217,3.023l-9-2a1.024,1.024,0,0,0-.434,0l-9,2A1,1,0,0,0,2,4V14a10,10,0,0,0,20,0V4A1,1,0,0,0,21.217,3.023ZM17,17a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V12a1,1,0,0,1,1-1H9V9a3,3,0,0,1,6,0v2h1a1,1,0,0,1,1,1Z"
                    fill="#ffffff"></path>
                  <path d="M12,8a1,1,0,0,0-1,1v2h2V9A1,1,0,0,0,12,8Z" fill="#ffffff"></path>
                </g>
              </svg>
            </div>
            Security
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Emphasized security for handling sensitive training content and
            proprietary information.
          </dd>
        </div>
        <div class="feature-card">
          <dt class="feature-header">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>ic_speaker_notes_off_24px</title>
                <g fill="#ffffff">
                  <path
                    d="M10.54 11l-.54-.54L7.54 8 6 6.46 2.38 2.84 1.27 1.73 0 3l2.01 2.01L2 22l4-4h9l5.73 5.73L22 22.46 17.54 18l-7-7zM8 14H6v-2h2v2zm-2-3V9l2 2H6zm14-9H4.08L10 7.92V6h8v2h-7.92l1 1H18v2h-4.92l6.99 6.99C21.14 17.95 22 17.08 22 16V4c0-1.1-.9-2-2-2z">
                  </path>
                </g>
              </svg>
            </div>
            Disable Comment Panel
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Provides flexibility in the review process, allowing distraction-free
            content views.
          </dd>
        </div>
        <div class="feature-card">
          <dt class="feature-header">
            <div class="">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <title>binary-code</title>
                <g fill="#ffffff">
                  <path
                    d="M3,22c-1.103,0-2-.897-2-2v-6.172c0-.263-.107-.521-.293-.707l-.414-.414c-.391-.391-.391-1.023,0-1.414l.414-.414c.186-.187,.293-.444,.293-.707V4c0-1.103,.897-2,2-2,.552,0,1,.447,1,1s-.448,1-1,1h-.001v6.172c.001,.67-.217,1.307-.62,1.828,.403,.521,.621,1.158,.621,1.828v6.172c.552,0,1,.447,1,1s-.448,1-1,1Z"
                    fill="#ffffff"></path>
                  <path
                    d="M21,22c-.552,0-1-.447-1-1s.448-1,1-1v-6.172c0-.67,.218-1.307,.621-1.828-.403-.521-.621-1.158-.621-1.828V4c-.552,0-1-.447-1-1s.448-1,1-1c1.103,0,2,.897,2,2v6.172c0,.263,.107,.521,.293,.707l.414,.414c.391,.391,.391,1.023,0,1.414l-.414,.414c-.186,.187-.293,.444-.293,.707v6.172c0,1.103-.897,2-2,2Z"
                    fill="#ffffff"></path>
                  <path
                    d="M9,11c-.552,0-1-.447-1-1V5h-1c-.552,0-1-.447-1-1s.448-1,1-1h2c.552,0,1,.447,1,1v6c0,.553-.448,1-1,1Z"
                    fill="#ffffff"></path>
                  <path
                    d="M17,21c-.552,0-1-.447-1-1v-5h-1c-.552,0-1-.447-1-1s.448-1,1-1h2c.552,0,1,.447,1,1v6c0,.553-.448,1-1,1Z"
                    fill="#ffffff"></path>
                  <path
                    d="M15,11c-1.654,0-3-1.346-3-3v-2c0-1.654,1.346-3,3-3s3,1.346,3,3v2c0,1.654-1.346,3-3,3Zm0-6c-.551,0-1,.448-1,1v2c0,.552,.449,1,1,1s1-.448,1-1v-2c0-.552-.449-1-1-1Z">
                  </path>
                  <path
                    d="M9,21c-1.654,0-3-1.346-3-3v-2c0-1.654,1.346-3,3-3s3,1.346,3,3v2c0,1.654-1.346,3-3,3Zm0-6c-.551,0-1,.448-1,1v2c0,.552,.449,1,1,1s1-.448,1-1v-2c0-.552-.449-1-1-1Z">
                  </path>
                </g>
              </svg>

            </div>
            SCORM Log
          </dt>
          <dd class="mt-2 text-base leading-7 text-gray-600">
            Collection of SCORM data for each reviewer, a pivotal tool for
            troubleshooting and understanding course-LMS communication.
          </dd>
        </div>
      </dl>

    </div>


    <button v-show="showScrollTop"
      class="fixed bottom-16 left-4 border-2 border-white bg-fuchsia-800 hover:bg-fuchsia-700 text-white font-semibold size-12 rounded-full"
      v-scroll-to="'#topnav'">
      <font-awesome-icon icon="fa-solid fa-circle-arrow-up" />
    </button>
  </div>
  <div v-if="isVideoModalOpen" class="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center"
    @click="isVideoModalOpen = false">
    <div class="bg-white pt-1 p-2 rounded-lg w-11/12 ">
      <div class="flex justify-end p-1">
        <button @click="isVideoModalOpen = false"
          class="px-2 text-gray-500 hover:text-gray-700 rounded border border-gray-200 hover:border-gray-400">
          <font-awesome-icon icon="fa-solid fa-times" />
        </button>
      </div>
      <div class="aspect-video">
        <iframe class="w-full h-full" :src="videoUrl" title="YouTube video" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
      </div>

    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.hide-mobile {
  @apply hidden md:block;
}

.feature-card {
  @apply relative bg-emerald-100/10 border border-green-200/50 rounded p-3;
}

.feature-header {
  @apply text-base font-semibold leading-7 text-gray-900 flex gap-1
}

.feature-header div {
  @apply flex h-10 w-10 items-center justify-center rounded-lg bg-green-600
}
</style>
