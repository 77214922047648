<template>
    <div class="">
        <div class="mx-auto md:text-3xl font-bold text-blue-700 text-center">Trusted by</div>
        <div class=" overflow-hidden whitespace-nowrap ">
            <div class=" inline-flex animate-scroll">
                <div v-for="(set, index) in [0, 1]" :key="index" class="flex ">
                    <img v-for="logo in logos" :key="`${index}-${logo.alt}`"
                        class="max-h-12 w-auto min-w-36 mx-1 object-contain grayscale" :src="logo" loading="lazy" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
const logos = ref([
    '/logos/clients/1.png',
    '/logos/clients/2.png',
    '/logos/clients/3.png',
    '/logos/clients/4.png',
    '/logos/clients/5.png',
    '/logos/clients/6.png',
    '/logos/clients/7.png',
    '/logos/clients/8.png',
    '/logos/clients/9.png',
    '/logos/clients/10.png',
    '/logos/clients/11.png',
    '/logos/clients/12.png',
    '/logos/clients/13.png',
    '/logos/clients/14.png',
    '/logos/clients/15.png',
    '/logos/clients/16.png',
]);


</script>

<style scoped>
@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}

.animate-scroll {
    animation: scroll 90s linear infinite;
}
</style>
